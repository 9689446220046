import { gql } from 'apollo-boost';

export const DELIVERY_LIST_QUERY = gql`
  query GetDeliveryListByFilter(
    $filter: DeliveryFilterInput!
    $page: Int
    $size: Int
  ) {
    delivery {
      deliveries(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            order {
              id
            }
            customer {
              id
              nickname
            }
            cost {
              value
              currencyCode
            }
            items(includeCancel: true) {
              id
              count
              orderItem {
                id
              }
              info {
                id
                product {
                  id
                  title
                }
                name
                price {
                  sale
                  retail
                  currencyCode
                }
              }
            }
            recipient {
              name
              email
              mobileNo
              phoneNo
              address {
                selectedType
                zoneCode
                jibunAddress
                roadAddress
                buildingName
                addressDetail
              }
              note
            }
            createdAt
            packedAt
            issuedAt
            completedAt
            confirmedAt
            troubledAt
            troubleReason
            status
            courier {
              id
              name
            }
            trackingNo
          }
        }
      }
    }
  }
`;
