export enum DeliveryState {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  ISSUED = 'ISSUED',
  PACKING = 'PACKING',
  PAID = 'PAID',
  PREPARING = 'PREPARING',
  TROUBLED = 'TROUBLED',
}

export const DeliveryStateLabels = [
  {
    value: DeliveryState.PAID,
    label: '결제 완료',
  },
  {
    value: DeliveryState.PACKING,
    label: '배송 준비중',
  },
  {
    value: DeliveryState.ISSUED,
    label: '배송중',
  },
  {
    value: DeliveryState.COMPLETED,
    label: '배송 완료',
  },
  {
    value: DeliveryState.CONFIRMED,
    label: '구매 확정',
  },
  {
    value: DeliveryState.CANCELED,
    label: '배송 취소',
  },
  {
    value: DeliveryState.TROUBLED,
    label: '배송 사고',
  },
  // {
  //   value: DeliveryState.PREPARING,
  //   label: '상품 준비중',
  // },
];
