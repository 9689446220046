import { gql } from 'apollo-boost';

export const PREPARE_DELIVERY_MUTATION = gql`
  mutation PrepareDeliveries($ids: [String!]!) {
    prepareDeliveryGoods(ids: $ids) {
      id
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;

export const PACK_DELIVERY_MUTATION = gql`
  mutation PackDeliveries($ids: [String!]!) {
    packDeliveries(ids: $ids) {
      id
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;

export const ISSUE_DELIVERY_MUTATION = gql`
  mutation IssueDeliveries($trackingInfos: [TrackingInfoInput!]!) {
    issueDeliveries(trackingInfos: $trackingInfos) {
      id
      courier {
        id
        name
      }
      trackingNo
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;

export const COMPLETE_DELIVERY_MUTATION = gql`
  mutation CompleteDeliveries($ids: [String!]!) {
    completeDeliveries(ids: $ids) {
      id
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;

export const CONFIRM_DELIVERY_MUTATION = gql`
  mutation ConfirmDeliveries($ids: [String!]!) {
    confirmDeliveries(ids: $ids) {
      id
      courier {
        id
        name
      }
      trackingNo
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;

export const RETREAT_PACKING_DELIVERY_MUTATION = gql`
  mutation RetreatPackingDeliveries($ids: [String!]!) {
    retreatPackingDeliveries(ids: $ids) {
      id
      courier {
        id
        name
      }
      trackingNo
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;

export const TROUBLE_DELIVERY_MUTATION = gql`
  mutation TroubleDeliveries($ids: [String!]!, $troubleReason: String!) {
    troubleDeliveries(ids: $ids, troubleReason: $troubleReason) {
      id
      courier {
        id
        name
      }
      trackingNo
      packedAt
      issuedAt
      completedAt
      confirmedAt
      troubledAt
      troubleReason
      status
    }
  }
`;
