







import Vue from 'vue';
import DeliverySearchForm from '../components/DeliverySearchForm.vue';
import DeliveryList from '../components/DeliveryList.vue';

export default Vue.extend({
  name: 'DeliveryListContainer',
  components: {
    DeliveryList,
    DeliverySearchForm,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
