




import Vue from 'vue';
import { DeliveryStateLabels } from '../../model/DeliveryState';

export default Vue.extend({
  name: 'DeliveryStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        DeliveryStateLabels.find(i => i.value.toString() == this.status)
          ?.label || ''
      );
    },
    // variant: function (): string {
    //   return (
    //     RefundStateLabels.find(i => i.value.toString() == this.status)?.color ||
    //     'secondary'
    //   );
    // },
  },
});
