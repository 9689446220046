














































































































































































































import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { DELIVERY_LIST_QUERY } from '../graphql/query';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import { Term } from '@/common/Term';
import { DeliveryFilter, DeliveryFilterQuery } from '../model/DeliveryFilter';
import { DeliveryState } from '../model/DeliveryState';
import { Address, AddressType, Delivery } from '../model/Delivery';
import DeliveryStatusLabel from './controls/DeliveryStatusLabel.vue';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { apolloClient } from '@/apolloClient';
import { DeliveryService } from '../service/DeliveryService';
import { isHostAdmin } from '@/env';
import { toStringArray } from '@/common/util/toStringArray';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

const service = new DeliveryService(apolloClient);

export default ContainerMixin.extend({
  name: 'DeliveryList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    Spinner,
    MoneyLabel,
    DeliveryStatusLabel,
  },
  data() {
    return {
      loading: 0,
      deliveries: [] as Delivery[],
      selection: [] as Delivery[],
      totalCount: 0,
      size: 20,
      isAdmin: !isHostAdmin(),
      troubleReasonModal: false,
      troubleReasonModalData: {
        reason: '' as string,
      },
      DeliveryState,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): DeliveryFilter {
      const query = this.$route.query as DeliveryFilterQuery;

      var term: Term | null = null;
      if (query.startedAt || query.endedAt) {
        term = {
          startedAt: query.startedAt ? Number(query.startedAt) : null,
          endedAt: query.endedAt ? Number(query.endedAt) : null,
        };
      }
      return {
        addressLike: query.address || null,
        courierIds: toStringArray(query.courierIds) || null,
        customerIds: toStringArray(query.customerIds) || null,
        orderIds: toStringArray(query.orderIds) || null,
        orderItemIds: toStringArray(query.orderItemIds) || null,
        ids: toStringArray(query.ids) || null,
        emailLike: query.email || null,
        hostId: query.hostId || null,
        nameLike: query.name || null,
        phoneLike: query.phone || null,
        recipientEmailLike: query.recipientEmail || null,
        recipientNameLike: query.recipientName || null,
        recipientPhoneLike: query.recipientPhone || null,
        statusIn:
          toStringArray(query.statusIn)?.map(i => i as DeliveryState) || null,

        term: term || null,
        trackingNo: query.trackingNo || null,
      };
    },
    trimmedTroubleReason(): string {
      return this.troubleReasonModalData.reason.trim();
    },
  },
  methods: {
    handleSelectionChange(items: Delivery[]): void {
      console.log('handleSelectionChange:', items);
      this.selection = items;
    },
    getCourierName(row: Delivery): string {
      return row.courier?.name || '';
    },
    getAddressInfo(address: Address): string {
      var addr = address.jibunAddress;
      if (address.selectedType === AddressType.ROAD) {
        addr = address.roadAddress + ' ' + address.buildingName;
      }

      return `${address.zoneCode} ${addr} ${address.addressDetail}`;
    },
    canPreparing(): boolean {
      if (this.selection.length === 0) {
        return false;
      }
      return this.selection.every(i => i.status === DeliveryState.PAID);
    },
    canPacking(): boolean {
      if (this.selection.length === 0) {
        return false;
      }
      return this.selection.every(
        i =>
          i.status === DeliveryState.PAID ||
          i.status === DeliveryState.PREPARING
      );
    },
    canIssuing(): boolean {
      if (this.selection.length === 0) {
        return false;
      }
      return this.selection.every(i => i.status === DeliveryState.PACKING);
    },
    canComplating(): boolean {
      if (this.selection.length === 0) {
        return false;
      }
      return this.selection.every(i => i.status === DeliveryState.ISSUED);
    },
    canConfirmation(): boolean {
      if (this.selection.length === 0) {
        return false;
      }
      return this.selection.every(
        i =>
          i.status === DeliveryState.COMPLETED ||
          i.status === DeliveryState.ISSUED
      );
    },
    async onClickPreparing() {
      console.log('onClickPreparing');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.prepare(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '상품 준비 실패');
      }
    },
    async onClickPacking() {
      console.log('onClickPacking');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.pack(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '배송 준비 실패');
      }
    },
    async onClickIssuing() {
      console.log('onClickIssuing');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.issue(
          this.selection.map(i => {
            return {
              deliveryId: i.id,
              courierId: null,
              trackingNo: null,
            };
          })
        );
      } catch (e) {
        this.showErrorAlert(e, '배송 출고 실패');
      }
    },
    async onClickCompleting() {
      console.log('onClickCompleting');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.complete(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '배송 완료 실패');
      }
    },
    async onClickConfirmation() {
      console.log('onClickConfirmation');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.confirm(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '구매 확정 실패');
      }
    },
    async onClickRetreating() {
      console.log('onClickRetreating');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.retreatPacking(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '배송 철회 실패');
      }
    },
    openReportModal(): void {
      if (this.selection.length === 0) {
        this.$alert('선택된 항목이 없습니다.', '실패');
      }
      this.troubleReasonModalData.reason = '';
      this.troubleReasonModal = true;
    },
    async onClickTroubling() {
      console.log('onClickTroubling');
      if (this.selection.length === 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }
      try {
        await service.trouble(
          this.selection.map(i => i.id),
          this.troubleReasonModalData.reason.trim()
        );
      } catch (e) {
        this.showErrorAlert(e, '배송 사고 처리 실패');
      } finally {
        this.troubleReasonModal = false;
      }
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    toDateString(date: number | null): string {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    refresh() {
      this.$apollo.queries.deliveries.refresh();
    },
  },
  apollo: {
    deliveries: {
      query: DELIVERY_LIST_QUERY,
      variables(): { page: number; size: number; filter: DeliveryFilter } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          delivery: { deliveries: Connection<Delivery> };
        }>
      ) {
        this.totalCount = result.data.delivery.deliveries.totalCount;
      },
      update: (data: { delivery: { deliveries: Connection<Delivery> } }) =>
        data.delivery.deliveries.edges.map(edge => edge.node),
    },
  },
});
