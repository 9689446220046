




































































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import Icon from '@/components/Icon/Icon.vue';
import { DeliveryFilterQuery } from '../model/DeliveryFilter';
import { Term } from '@/common/Term';
import FripButton from '../../../components/FripComponents/FripButton.vue';
import VueRouter from 'vue-router';
import { DeliveryState, DeliveryStateLabels } from '../model/DeliveryState';
import { toStringArray } from '@/common/util/toStringArray';

export default Vue.extend({
  name: 'DeliverySearchForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    FripButton,
    Icon,
  },
  data() {
    const query = this.$route.query as DeliveryFilterQuery;

    let searchTarget = 'customerId';
    let searchKeyword = '';

    console.log('query:', query);

    if (query.customerIds) {
      searchTarget = 'customerId';
      searchKeyword = toStringArray(query.customerIds)?.join(',') || '';
    } else if (query.orderIds) {
      searchTarget = 'orderId';
      searchKeyword = toStringArray(query.orderIds)?.join(',') || '';
    } else if (query.ids) {
      searchTarget = 'deliveryId';
      searchKeyword = toStringArray(query.ids)?.join(',') || '';
    } else if (query.address) {
      searchTarget = 'address';
      searchKeyword = query.address;
    } else if (query.recipientName) {
      searchTarget = 'recipientName';
      searchKeyword = query.recipientName;
    } else if (query.recipientPhone) {
      searchTarget = 'recipientPhone';
      searchKeyword = query.recipientPhone;
    } else if (query.trackingNo) {
      searchTarget = 'trackingNo';
      searchKeyword = query.trackingNo;
    }

    return {
      totalCount: 0,
      searchOptions: [
        { value: 'customerId', text: '고객ID' },
        { value: 'orderId', text: '주문 ID' },
        { value: 'deliveryId', text: '배송 ID' },
        { value: 'address', text: '주소' },
        { value: 'recipientName', text: '받는 사람' },
        { value: 'recipientPhone', text: '연락처' },
        { value: 'trackingNo', text: '송장번호' },
      ],
      stateLabels: DeliveryStateLabels,
      searchTarget: searchTarget,
      searchKeyword: searchKeyword,
      searchTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      statusIn:
        query.statusIn?.map(i => i as DeliveryState) ||
        DeliveryStateLabels.map(i => i.value),
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (this.searchTarget === 'customerId') {
        return '고객 아이디를 , 로 구분해 입력해주세요.';
      } else if (this.searchTarget === 'orderId') {
        return '주문 아이디를 , 로 구분해 입력해주세요.';
      } else if (this.searchTarget === 'deliveryId') {
        return '배송 아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검색어를 입력해주세요';
      }
    },
    allStatusChecked: {
      get(): boolean {
        return this.statusIn.length === DeliveryStateLabels.length;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.statusIn = DeliveryStateLabels.map(i => i.value);
        } else if (this.statusIn.length === DeliveryStateLabels.length) {
          this.statusIn = [];
        }
      },
    },
  },
  methods: {
    clear(): void {
      this.searchTarget = 'customerId';
      this.searchKeyword = '';
      this.searchTerm = { startedAt: null, endedAt: null };
      this.statusIn = DeliveryStateLabels.map(i => i.value);
      this.$router.push({ query: undefined }).catch();
    },
    buildFilter(): void {
      const filter: DeliveryFilterQuery = {};

      if (this.searchKeyword) {
        if (this.searchTarget === 'customerId') {
          filter.customerIds = this.searchKeyword.split(',').map(i => i.trim());
        } else if (this.searchTarget === 'orderId') {
          filter.orderIds = this.searchKeyword.split(',').map(i => i.trim());
        } else if (this.searchTarget === 'deliveryId') {
          filter.ids = this.searchKeyword.split(',').map(i => i.trim());
        } else if (this.searchTarget === 'address') {
          filter.address = this.searchKeyword;
        } else if (this.searchTarget === 'recipientName') {
          filter.recipientName = this.searchKeyword;
        } else if (this.searchTarget === 'recipientPhone') {
          filter.recipientPhone = this.searchKeyword;
        } else if (this.searchTarget === 'trackingNo') {
          filter.trackingNo = this.searchKeyword;
        }
      }

      if (this.searchTerm.startedAt) {
        filter.startedAt = this.searchTerm.startedAt.toString();
      }

      if (this.searchTerm.endedAt) {
        filter.endedAt = this.searchTerm.endedAt.toString();
      }

      filter.statusIn = this.statusIn;

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
  },
});
