import { DeliveryState } from './DeliveryState';

export interface Delivery {
  id: string;
  order: {
    id: string;
  };
  customer: {
    id: string;
    nickname: string;
  };
  cost: {
    value: string;
    currencyCode: string;
  };
  items: DeliveryItem[];
  recipient: {
    name: string;
    email: string | null;
    mobileNo: string;
    phoneNo: string;
    address: Address;
    note: string | null;
  };
  packedAt: number | null;
  issuedAt: number | null;
  completedAt: number | null;
  confirmedAt: number | null;
  courier: {
    id: string;
    name: string;
  } | null;
  trackingNo: string;
  status: DeliveryState;
}

export interface Address {
  selectedType: AddressType | null;
  zoneCode: string;
  jibunAddress: string;
  roadAddress: string;
  buildingName: string;
  addressDetail: string;
}

export enum AddressType {
  ROAD = 'ROAD',
  JIBUN = 'JIBUN',
}

export interface DeliveryItem {
  id: string;
  orderItem: {
    id: string;
  };
  count: number;
  info: {
    id: string;
    name: string;
    product: {
      id: string;
      title: string;
    };
    price: {
      sale: string;
      retail: string;
      currencyCode: string;
    };
  };
}
