import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  COMPLETE_DELIVERY_MUTATION,
  CONFIRM_DELIVERY_MUTATION,
  ISSUE_DELIVERY_MUTATION,
  PACK_DELIVERY_MUTATION,
  PREPARE_DELIVERY_MUTATION,
  RETREAT_PACKING_DELIVERY_MUTATION,
  TROUBLE_DELIVERY_MUTATION,
} from '../graphql/mutation';
import { TrackingInfo } from '../model/TrackingInfo';

export class DeliveryService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async prepare(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: PREPARE_DELIVERY_MUTATION,
      variables: {
        ids: ids,
      },
    });
    console.log('prepare:', data.prepareDeliveryGoods);
  }

  public async pack(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: PACK_DELIVERY_MUTATION,
      variables: {
        ids: ids,
      },
    });
    console.log('pack:', data.packDeliveries);
  }

  public async issue(trackingInfos: TrackingInfo[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: ISSUE_DELIVERY_MUTATION,
      variables: {
        trackingInfos: trackingInfos,
      },
    });
    console.log('issue:', data.issueDeliveries);
  }

  public async complete(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: COMPLETE_DELIVERY_MUTATION,
      variables: {
        ids: ids,
      },
    });
    console.log('complete:', data.completeDeliveries);
  }

  public async confirm(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: CONFIRM_DELIVERY_MUTATION,
      variables: {
        ids: ids,
      },
    });
    console.log('confirm:', data.confirmDeliveries);
  }

  public async retreatPacking(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: RETREAT_PACKING_DELIVERY_MUTATION,
      variables: {
        ids: ids,
      },
    });
    console.log('retreatPacking:', data.retreatPackingDeliveries);
  }

  public async trouble(ids: string[], troubleReason: string): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: TROUBLE_DELIVERY_MUTATION,
      variables: {
        ids,
        troubleReason,
      },
    });
    console.log('trouble:', data.troubleDeliveries);
  }
}
